import { Header } from "./Header"
import { Footer } from "./Footer"
import "./style.sass"
import { PropsWithChildren } from "react"

export const Layout = ({ children, withoutMain = false }: PropsWithChildren & { withoutMain?: boolean }) => {
  return (
    <div className="foxy-layout">
      <Header />
      {withoutMain ? children : <main className="foxy-main">{children}</main>}
      <Footer />
    </div>
  )
}
