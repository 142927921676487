import clsx from "clsx"
import "./style.sass"
import { ReactNode } from "react"

type ButtonColor = "error" | "error-light" | "primary" | "success" | "inherit"
type ButtonVariant = "contained" | "outlined" | "text"
interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  color?: ButtonColor
  variant?: ButtonVariant
  size?: "sm" | "md"
  type?: "button" | "submit" | "reset"
}
export const Button = ({ size = "md", leftIcon, rightIcon, type = "button", variant = "contained", color = "primary", ...props }: IButtonProps) => {
  return (
    <button
      {...props}
      className={clsx("custom-button", `custom-button__${variant}-${color}`, `custom-button__size-${size}`, props.className)}
      type={type}
    >
      {leftIcon != null && leftIcon}
      {props.children}
      {rightIcon != null && rightIcon}
    </button>
  )
}
