import ReactDOM from "react-dom/client"
import "./style.sass"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "simplebar-react/dist/simplebar.min.css"
import "react-toastify/dist/ReactToastify.min.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)

reportWebVitals()
