import { lazy } from "react"
import { Navigate } from "react-router-dom"

const authRoutes = [
  {
    path: "/auth",
    element: <Navigate to="login" />,
  },
  {
    path: "signup",
    Component: lazy(async () => await import("./Signup")),
  },
  {
    path: "login",
    Component: lazy(async () => await import("./Login")),
  },
  {
    path: "forgot-password",
    Component: lazy(async () => await import("./Recovery")),
  },
]

export default authRoutes
