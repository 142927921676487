import { Navigate, RouteObject } from "react-router-dom"
import { lazy } from "react"
import orderCardRoutes from "./Dashboard/OrderCard/routes"

const dashboardRoutes: RouteObject[] = [
  {
    path: "/dashboard",
    element: <Navigate to="order-card" replace />,
  },
  {
    path: "/dashboard/order-card",
    Component: lazy(async () => await import("./Dashboard/OrderCard")),
    children: orderCardRoutes,
  },
]

export default dashboardRoutes
