import { lazy } from "react"
import { Navigate } from "react-router-dom"

const orderCardRoutes = [
  {
    path: "/dashboard/order-card",
    element: <Navigate to="select" replace />,
  },
  {
    path: "select",
    Component: lazy(async () => await import("./Sections/SelectCards")),
  },
  {
    path: "confirmation",
    Component: lazy(async () => await import("./Sections/OrderConfirmation")),
  },
  {
    path: "instruction",
    Component: lazy(async () => await import("./Sections/OrderInstruction")),
  },
  {
    path: "transaction-id",
    Component: lazy(async () => await import("./Sections/TransactionID")),
  },
]

export default orderCardRoutes
