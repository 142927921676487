import { Container } from "@/components/Container"
import "./style.sass"
import { PropsWithChildren } from "react"
import { Link, NavLink } from "react-router-dom"

const FooterLinks = ({ title, children }: { title: string } & PropsWithChildren) => {
  return (
    <div className="footer-links">
      <h3 className="uppercase">{title}</h3>
      <div className="footer-links__list gap-2.5">{children}</div>
    </div>
  )
}
export const Footer = () => {
  return (
    <div className="foxy-footer">
      <Container>
        <div className="foxy-footer__wrapper">
          <div className="foxy-footer__left">
            <Link to="/">
              <img src="/assets/logoWithText.svg" alt="Foxy logo" />
            </Link>
            <p className="text-label text-base my-5">
              Foxycard delivers Visa and Mastercard prepaid virtual credit cards, making your payments seamless and completely anonymous across the
              globe
            </p>
          </div>
          <div className="foxy-footer__right">
            <FooterLinks title="Platform">
              <NavLink to="/dashboard/order-card">Order Card</NavLink>
              <NavLink to="/check-balance">Check Balance</NavLink>
              <NavLink to="/affiliate">Affiliate Program</NavLink>
              <NavLink to="/blogs">Blog</NavLink>
            </FooterLinks>
            <FooterLinks title="About Cards">
              <NavLink to="#">Where to use Cards</NavLink>
              <NavLink to="#">How to Pay</NavLink>
            </FooterLinks>
            <FooterLinks title="Help">
              <NavLink to="/contact-us">Contact Us</NavLink>
              <NavLink to="/faq">FAQ</NavLink>
            </FooterLinks>
          </div>
        </div>
        <p className="text-label text-base my-5 bottom-p">
          Foxycard delivers Visa and Mastercard prepaid virtual credit cards, making your payments seamless and completely anonymous across the globe
        </p>
        <div className="flex justify-center gap-5 p-5 lg:pl-0 md:justify-start">
          <img src="/assets/visa.png" alt="Visa" />
          <img src="/assets/mastercard.png" alt="Visa" />
          <img src="/assets/3ds.png" alt="Visa" />
          <img src="/assets/certified.png" alt="Visa" />
        </div>
      </Container>
    </div>
  )
}
