import { Container } from "@/components/Container"
import "./style.sass"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { Button } from "@/components/Button"
import { Icon } from "@/components/Icons"
import { useEffect, useState } from "react"
import clsx from "clsx"
import { useMediaQuery } from "@/hooks"

export const Header = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery("(min-width: 1024px)")
  useEffect(() => {
    setOpen(false)
  }, [isDesktop])
  return (
    <div className="foxy-header">
      <Container className="flex items-center gap-[41px]">
        <Link to="/">
          <img className="foxy-header__logo" src="/assets/logoWithText.svg" alt="Foxy logo" />
        </Link>
        <div className="foxy-header__links flex-1">
          <div className="foxy-header__links--left">
            <NavLink to="/dashboard/order-card">How to pay</NavLink>
            <NavLink to="/faq">FAQs</NavLink>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </div>
          <div className="foxy-header__links--right">
            <NavLink to="/auth/login">Login</NavLink>
            <NavLink to="/auth/signup">Sign Up</NavLink>
          </div>
        </div>
        <div className="flex justify-end">
          <Button color="error-light" className="foxy-header__buy-button" onClick={() => navigate("/dashboard/order-card")}>
            Buy Cards
          </Button>
        </div>
        <div className="foxy-header__menu-button" onClick={() => setOpen(!open)}>
          <Icon icon="Menu" size={32} color="white" />
        </div>
        <div className={clsx("foxy-header__drawer", open && "foxy-header__drawer--open")} onClick={() => setTimeout(() => setOpen(false), 10)}>
          <div className="foxy-header__drawer--links">
            <NavLink to="/dashboard/order-card">How to pay</NavLink>
            <NavLink to="/faq">FAQs</NavLink>
            <NavLink to="/contact-us">Contact Us</NavLink>
            <div className="foxy-header__drawer--auth">
              <NavLink to="/auth/login">Login</NavLink>
              <NavLink to="/auth/signup">Sign Up</NavLink>
            </div>
          </div>
          <Button color="error-light" className="foxy-header__drawer--buy-button" onClick={() => navigate("/dashboard/order-card")}>
            Buy Cards
          </Button>
        </div>
      </Container>
    </div>
  )
}
