import { Layout } from "@/layout"
import { Suspense } from "react"
import { Outlet } from "react-router-dom"

export const AuthView = () => {
  return (
    <Layout>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Outlet />
      </Suspense>
    </Layout>
  )
}
